/* -----------------------------------------
   EVENT PLUGIN STYLE
----------------------------------------- */

.ecs-event .card .card-body p .text-muted {
  color: $primary !important;
}

@include media-breakpoint-up(md) {
  .ecs-events {
    .ecs-event:nth-child(2n) {
      border-left: solid 1px #ffffff;
      border-right: solid 1px #ffffff;
    }
  }
}
@include media-breakpoint-down(xs) {
  .ecs-events {
    .ecs-event:nth-child(2n) {
      border-top: solid 1px #ffffff;
      border-bottom: solid 1px #ffffff;
    }
  }
}

html body.single-tribe_events #tribe-events-pg-template, .tribe-events-pg-template{
  max-width: 100%;
  padding: 0px;
}

html body.single-tribe_events .tribe-events-pg-template>#tribe-events{
  padding: 0px;
}

.ecs-event {
  .ecs-wrap{
    background-color: transparent !important;

    .singleevent{
      text-align: center;
    }
    .text-muted{
      * {
        font-weight: bold;
        color: $graublau !important;
        text-transform: uppercase;
      }
    }
    .card-title{
      a{
        color: $primary;
        font-size: 22*$px-base;
        text-decoration: none !important;
      }
    }
    .icon{
      width: 62px;
      height: 62px;
      margin:auto;
      path, rect{
        fill: $secondary;
      }
    }
    .card-footer{
      .btn{
        background-color: #d5dde0;
        color: $primary;
        border-color: $primary;
      }
    }
    .card-body{
      min-height: 160px;
    }
    .card-img-top{
      height: auto;
    }
    .tnbutton{
      margin-top: 35px;
      justify-content: center;
      a{
        background-color: $tertiary;
        &:hover{
          background-color: $tertiaryhover;
        }
      }
    }
  }
}


html body #tribe-events{
  .tribe-events-ical, .tribe-events-gcal{
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.175rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 20px;
    text-transform: uppercase;
    background-color: #ffffff;
    border-color: #233240;
    color: #233240;
    font-size: 12*$px-base;
  }

  .tribe-events-event-image {
    text-align: left;
    img {

      max-width: 365px;
    }
  }
  .tribe-events-event-meta{
    background-color: $quaternary;
  }
  .tribe-events-title-bar{
    display: none;
  }
}

#tribe-events-header{
  .tribe-events-sub-nav{
    display: none;
  }
}

.tribe-events-tooltip{
  .tribe-events-event-thumb{
    display: none;
  }
}

.tribeteilnehmerliste{
  width: 100%;
}

.tribe-tickets-order_status-row, .tribe-tickets-attendees-list-optout{
  display: none !important;
}


html body #tribe-events .tribe-events-list{
  .tribe-events-event-meta{
    background-color: #ffffff !important;
  }
}

.tribe-events-related-events-title{
  font-size: 24*$px-base;
  color: $graublau;
  text-align: center;
  margin: 40px 0px !important;
  background-image: url("images/icons/tnicons/tn3.svg");
  background-size: 80px;
  background-repeat: no-repeat;
  background-position: top center;
  padding-top: 70px;
}

.tribe-related-events-thumbnail img{
  width: 324px !important;
  height: 172px !important;
}

.tribe-related-events{
  .tribe-related-event-info{
    min-height: 270px;
  }
}

.tribe-events-tickets header{
  font-weight: bold !important;
}

.tribe-events-sub-nav a{
  color: #ffffff !important;
}

#tribe-events-footer{
  .tribe-events-sub-nav a{
    color: $primary !important;
    @include media-breakpoint-down(md) {
      color: #ffffff !important;
    }
  }
}

.tribe-mobile-day .tribe-events-event-image{
  display: none;
}

html body #tribe-events-pg-template .tribe-events-content ul li{
  margin: 0px;
}

html body .tribe-events-single-event-description a {
  color: $tertiary !important;
}