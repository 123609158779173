.beteiligungsangebote{
  .headbox{
    background-color: $quaternary;
    padding: 20px;
  }
  .article *{
    color: $graublau !important;
  }
  a{
    color: $primary;
  }
  .footbox{
    background-color: $quaternary;
    padding: 20px;

    h3{
      font-size: 20px;
    }
  }
  .thumbnailboxwithquelle{
    width: 334px !important;
    img{
      width: 334px !important;
    }
  }
  .ba-box{
    display: flex;
    div{
      display: inline-block;
    }

    .label{
      flex-shrink: 0  ;
      width: 30px;
      margin-right: 15px;
      text-align: center;
    }
  }
  .button{
    color: #ffffff;
  }
  .page{
    max-width: 1140px !important;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }


  .page{
    margin: auto;
    padding: 0px;
  }

  .postgridview{
    padding-top: 30px;

    .type-beteiligungsangebote{
      .gueltigbis{
        text-decoration: underline;
      }
      a:hover{
        text-decoration: none;
        .article{
          background-color: $tertiary !important;
          h3{
            //color: $tertiary !important;
          }
        }
      }
      .article{
        background-color: $quaternary_light;
        padding: 20px;
        min-height: 400px;
      }


      h3{
        font-size: 22px;
      }
      .read-more a{
        font-weight: bold;
      }
      dt {
        width: 30px !important;
      }
      dt.euro{
        width: 25px !important;
      }
      dd{
        margin-left: 40px !important;
        @include media-breakpoint-down(sm) {
          margin-left: 0px !important;
        }
      }
    }
  }
}