.weiterbildungbox{
  .type-stellenangebote, .type-tribe_events{
   margin-bottom: 30px;
    border-bottom: solid 1px $primary;
  }
  .result-info{
    padding: 20px 0px;
    font-weight: bold;
  }
}

.weiterbildung-php{
  .weiterbildungbox{
    .solrsortbar{
      .container{
        width: 100% !important;
      }
    }
  }
}

.klimaregionenkarte{
  .regiohover{
    cursor: pointer;
    path{
      fill: rgba(0,0,0,0);
    }
    text{
      font-family: $font-family-base;
      font-size: 8px;
    }
  }
  .regiohover:hover{
    cursor: pointer;
    path{
      fill: #c01b2b;
    }
  }
}

.opendatakarte{
  .regiohover:hover, .regiohover.hover{
    cursor: pointer;
    &>polygon, &>path, &>polyline.st0, &>path.st0{
      fill: #c01b2b;
    }
  }
  #Hoverparkplatz, #Clickparkplatz{
    pointer-events: none;
  }
}

.opendatakarteinfobox{
  svg{
    .st10, .st11{
      font-family: $font-family-base;
      font-size: 15px;
    }
  }

  #opendatadata{
    background-color: $primary_light;
    padding: 20px;
    *{
      font-size: 15*$px-base;
    }

    h3{
      color: $primary;
    }

    .datarow{
      display: flex;

      .datacount{
        text-align: right;
        width: 55px;
        margin-right: 25px;
        color: $primary;
        font-weight: bold;
      }
    }

  }
}