.bottomnav {
  padding: 15px;
  margin-bottom: 10px;
  color: #ffffff;
  @include media-breakpoint-up(md) {
    text-align: center;
  }
  a{
    display: block;
    line-height: 2rem;
    color: #ffffff;
    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }
  .seperator{
    display: none;
    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }
}

.footer-gold{
  background-color: $primary;
}

.footerlogo{
  background-color: #ffffff;
  padding: 0px 10px 30px 10px;
}

.prefooter{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0px;

  .footernewsletterhinweis {
    display: block;
    min-width: 80%;

    .tnbutton{
      margin-top: 5px;
    }
  }
}
