/* -----------------------------------------
   SUCHE & SUCHSEITE
----------------------------------------- */

#search {
  width: 310px;
  margin: auto;
  margin-right: 35px;

  #searchbar{
    border-bottom: solid 1px $secondary;
    height: 45px;
  }
  form{
    input {
      border-radius: 0;
      background: transparent;
      border: none;
      color: $secondary;
      font-size: 18*$px-base;
    }

    input::placeholder {
      color: $secondary;
    }
  }
}

#searchbar{
  display: flex;
  justify-content: end;

  #searchtext{
    width: 250px;
    font-size: 24*$px-base;
  }
  #searchsubmit{
    background-image: url('images/icons/header/suche.svg') !important;
    width: 40px;
    height: 40px;
    border-bottom: none;
  }
  .wdm-dropdown-menu{
    *{
      color: #000000;
    }
  }
}

.page-template-weiterbildung, .page-template-stellenangebote{
  .article{
    border-bottom: solid 1px #91989f;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
}