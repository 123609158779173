
.goldenbackground{
  background-color: $quaternary;
  overflow-x: hidden;
  @include media-breakpoint-down(xs) {
    width: 110%;
  }
  .row{
    overflow-x: hidden;
  }
}

.hintergrundbild{
  *{
    color: #ffffff !important;
  }
  .vc_btn3{
    color: $tertiary !important;
  }
}

.svg200 img{
  width: 200px;
}

.newsletterbox{
  ul{
    list-style: none;

    li{
      display: inline-flex;
      align-items: end;
    }
    li:before{
        content:'';
        display:inline-block;
        height:36px;
        width: 45px;
        background-image:url("images/icons/haken.svg");
        background-size:45px;
        background-repeat:no-repeat;
        padding-left: 2em;
        margin-left: -39px;
    }
  }
}

//ACCODION STYLING
.wpb-js-composer .vc_tta.vc_general .vc_tta-panel.vc_active .vc_tta-panel-body{
 background-color: #ffffff;
}
.wpb-js-composer .vc_tta.vc_general {
  .vc_tta-panel-heading{
    border-radius: 0px !important;
    .vc_tta-title-text{
      font-size: 16*$px-base;
      font-weight: bold;
    }
    *{
      color: $primary !important;
      border-color: $primary !important;
    }
  }
}
.wpb-js-composer .vc_tta-color-grey.vc_tta-style-classic .vc_tta-controls-icon::after, .wpb-js-composer .vc_tta-color-grey.vc_tta-style-classic .vc_tta-controls-icon::before{
  border-color: $primary !important;
}
html body.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-controls-icon-position-left .vc_tta-controls-icon{
 top: 59%;
}

// TOUR STYLING
html body{
  &.wpb-js-composer .vc_tta-tabs{
    border: solid 1px $primary;
    &.vc_tta-color-turquoise {
      .vc_tta-tab>a{
        background-color: $primary;
      }
      .vc_tta-tab:nth-child(even)>a{
        background-color: $primaryhover;
      }
      .vc_tta-tab.vc_active>a{
        background-color: #ffffff !important;
        color: $primary
      }
      &.vc_tta-tabs .vc_tta-panels{
        background-color: #ffffff;
        *{
          color: #000000;
        }
      }
    }
  }
}


//Ansprechpartner Teaser
.ansprechpartnerteaser{
  margin-bottom: 80px;

  .vorschaubild{
    img{
      width: 100%;
      max-width: 340px;
    }
  }
  .info{
    padding-top: 20px;
  }
  .ansprechpartnerfooter{
    padding-top: 20px;
    &>div{
      display: flex;
      padding-bottom: 7px;
    }
    .icon{
      margin-right: 10px;
    }
  }
}

html body.wpb-js-composer .vc_tta-color-turquoise.vc_tta-style-modern .vc_tta-panel .vc_tta-panel-heading,
html body.wpb-js-composer .vc_tta-color-turquoise.vc_tta-style-modern .vc_tta-panel .vc_tta-panel-heading:focus,
html body.wpb-js-composer .vc_tta-color-turquoise.vc_tta-style-modern .vc_tta-panel .vc_tta-panel-heading:hover,
html body.wpb-js-composer .vc_tta-color-turquoise.vc_tta-style-flat .vc_tta-panel .vc_tta-panel-heading,
html body.wpb-js-composer .vc_tta-color-turquoise.vc_tta-style-flat .vc_tta-panel .vc_tta-panel-heading:focus,
html body.wpb-js-composer .vc_tta-color-turquoise.vc_tta-style-flat .vc_tta-panel .vc_tta-panel-heading:hover,
html body.wpb-js-composer .vc_tta-color-turquoise.vc_tta-style-flat .vc_tta-panel.vc_active .vc_tta-panel-heading{
  border-color: #39615e;
  background-color: #39615e;
  *{
    color: #ffffff !important;
    border-color: #ffffff !important;
  }
}

html body.wpb-js-composer .vc_tta-color-turquoise.vc_tta-style-modern .vc_tta-panel .vc_tta-panel-heading:hover,
html body.wpb-js-composer .vc_tta-color-turquoise.vc_tta-style-flat .vc_tta-panel .vc_tta-panel-heading:hover{
  *{
    color: #ffffff !important;
    border-color: #ffffff !important;
  }
}

html .wpb-js-composer .vc_tta-color-turquoise.vc_tta-style-modern .vc_tta-panel.vc_active .vc_tta-panel-heading,
html .wpb-js-composer .vc_tta-color-turquoise.vc_tta-style-flat .vc_tta-panel.vc_active .vc_tta-panel-heading{
  color: $primary !important;
  border-color: $primary !important;
}

html .wpb-js-composer .vc_tta-color-turquoise.vc_tta-style-modern .vc_tta-panel.vc_active .vc_tta-panel-heading *,
html .wpb-js-composer .vc_tta-color-turquoise.vc_tta-style-flat .vc_tta-panel.vc_active .vc_tta-panel-heading *{
  color: $primary !important;
  border-color: $primary !important;
}

html .vc_separator.vc_sep_color_green .vc_sep_line{
  border-color: #39615e;
}

html .vc_color-green.vc_message_box{
  background-color: #D7DFDF;
  border-color: #39615e;
}

html .vc_color-green.vc_message_box .vc_message_box-icon{
  color: #39615e;
  p{
    color: #000000;
  }
}
